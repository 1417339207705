// Slide down animation
.slide-out-down {
  animation-name: slideOutDown;
  -webkit-animation-duration: 0.35s;
  animation-duration: 0.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideOutDown {
  0% {
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY(100%);
  }
}
@keyframes slideOutDown {
  0% {
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY(100%);
  }
}

// Slide up animation
.slide-in-up {
  animation-name: slideInUp;
  -webkit-animation-duration: 0.35s;
  animation-duration: 0.35s;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
  0% {
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
