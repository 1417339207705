/// Set dynamic and responsive sizes using:
/// .w-auto, .w-md-, .min-w-, .min-w-md-, .max-w-, .max-w-md-, .h-auto, h-md-, .min-h-, .min-h-md-, .max-h-, max-h-md-,
/// .vw-, vs-md-, .min-vw-, .min-vw-md-, .max-vw-, .max-vw-md-, .vh-, .vh-md-, .min-vh-, .min-vh-md-,
/// .max-vh-, .max-w-md-, etc.
///
/// Sizes: auto, 10, 25, 50, 60, 75, or 100

$sizes: 10, 25, 40, 50, 60, 75, 100;

@each $size in $sizes {
  .min-w-#{$size} {
    min-width: #{$size} + "%";
  }

  .max-w-#{$size} {
    max-width: #{$size} + "%";
  }

  .min-h-#{$size} {
    min-height: #{$size} + "%";
  }

  .max-h-#{$size} {
    max-height: #{$size} + "%";
  }

  // Default width relative to viewport
  .vw-#{$size} {
    width: #{$size}vw !important;
  }

  // Min width relative to viewport
  .min-vw-#{$size} {
    min-width: #{$size}vw !important;
  }

  // Max width relative to viewport
  .max-vw-#{$size} {
    max-width: #{$size}vw !important;
  }

  // Default height relative to viewport
  .vh-#{$size} {
    height: #{$size}vh !important;
  }

  // Min height relative to viewport
  .min-vh-#{$size} {
    min-height: #{$size}vh !important;
  }

  // Max height relative to viewport
  .max-vh-#{$size} {
    max-height: #{$size}vh !important;
  }
}

// Same as above but with breakpoints
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-auto {
        width: auto !important;
      }

      .w-#{$breakpoint}-#{$size}  {
        width: #{$size} + "%" !important;
      }

      .min-w-#{$breakpoint}-#{$size} {
        min-width: #{$size} + "%" !important;
      }

      .max-w-#{$breakpoint}-#{$size} {
        max-width: #{$size} + "%" !important;
      }

      .vw-#{$breakpoint}-#{$size} {
        width: #{$size}vw !important;
      }

      .min-vw-#{$breakpoint}-#{$size} {
        min-width: #{$size}vw !important;
      }

      .max-vw-#{$breakpoint}-#{$size} {
        max-width: #{$size}vw !important;
      }

      .h-#{$breakpoint}-auto {
        height: auto !important;
      }

      .h-#{$breakpoint}-#{$size}  {
        height: #{$size} + "%" !important;
      }

      .min-h-#{$breakpoint}-#{$size} {
        min-height: #{$size} + "%" !important;
      }

      .max-h-#{$breakpoint}-#{$size} {
        max-height: #{$size} + "%" !important;
      }

      .vh-#{$breakpoint}-#{$size} {
        height: #{$size}vh !important;
      }

      .min-vh-#{$breakpoint}-#{$size} {
        min-height: #{$size}vh !important;
      }

      .max-vh-#{$breakpoint}-#{$size} {
        max-height: #{$size}vh !important;
      }
    }
  }
}

/// Set element width to `initial`

.w-initial {
  flex: none !important;
  width: initial !important;
}

/// Set element width to `fit-content`

.w-fit {
  width: fit-content !important;
}
