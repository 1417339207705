@import "stitches/gallery";
@import "stitches/in_progress";
@import "stitches/public_debug";
@import "stitches/reference_image";
@import "stitches/segment_photos";
@import "stitches/capture_guide";

.shrink-on-hover {
  transition: all 0.4s cubic-bezier(.25,.8,.25,1);

  &:hover {
    transform: scale(0.95);
  }
}

.field_with_errors {
  display: inline;
  padding: 0;
}

.required-field:before{
  content: "* ";
}

#next-step-button.disabled {
  @extend .btn-light, .bg-white, .border-secondary;
}

#rectification-canvas {
  max-height: calc(75dvh - 120px);
  max-width: 100%;
  object-fit: contain;
}
