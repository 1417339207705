.pswp__caption {
  background: rgba(75, 150, 75, 0.75);
  font-size: 16px;
  color: #fff;
  padding: 2px 12px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);

  a {
    color: #fff;
    text-decoration: underline;
  }
}
