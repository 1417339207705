#new-reference-image-form {
  .file-zone {
    height: 40vh;
    min-height: 225px;
    max-height: 320px;

    &.awaiting-drop {
      @extend .bg-success;
    }
  }
}

#rectification-content {
  position: absolute;
  top: $nav-height;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100dvh - #{$nav-height});
  min-height: 500px;

  #rectification-header {
    @extend .container;
    padding-bottom: 8px;
  }

  #rectification-body {
    flex-grow: 1;
    background-color: #000;
  }
}
