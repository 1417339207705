#capture-guide-content {
  position: absolute;
  top: $nav-height;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100dvh - #{$nav-height});
  min-height: 500px;
  max-height: 1000px;

  #capture-guide-header {
    @extend .container;
    padding-bottom: 8px;
  }

  #capture-guide-body {
    flex-grow: 7;
    background-color: #000;
    height: 0; // Required for containing the canvas

    canvas {
      max-width: 100%;
      max-height: 100%;
    }
  }

  #capture-guide-footer {
    flex-grow: 1;
    min-height: 40px;
    @extend .container;
  }
}
