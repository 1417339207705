/// Set border-radius to different values based on different breakpoints
//
/// .rounded-md-top-0, .rounded-md-end-1, .rounded-md-bottom-2, .rounded-md-start-3
/// .rounded-lg-top-0, .rounded-lg-end-1, .rounded-lg-bottom-2, .rounded-lg-start-3,
/// etc..

$border-radii: (
        0: 0,
        1: .2rem,
        2: .25rem,
        3: .3rem
);

@each $border-radius, $border-radius-value in $border-radii {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      .rounded-#{$breakpoint}-top-#{$border-radius} {
        border-top-left-radius: #{$border-radius-value};
        border-top-right-radius: #{$border-radius-value} !important;
      }
      .rounded-#{$breakpoint}-end-#{$border-radius} {
        border-top-right-radius: #{$border-radius-value} !important;
        border-bottom-right-radius: #{$border-radius-value};
      }
      .rounded-#{$breakpoint}-bottom-#{$border-radius} {
        border-bottom-left-radius: #{$border-radius-value} !important;
        border-bottom-right-radius: #{$border-radius-value};
      }
      .rounded-#{$breakpoint}-start-#{$border-radius} {
        border-top-left-radius: #{$border-radius-value};
        border-bottom-left-radius: #{$border-radius-value};
      }
    }
  }
}
