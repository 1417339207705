// Backgrounds

.bg-purple {
  background-color: $light-purple;
}

.bg-pink {
  background-color: $pink;
}

.bg-success-light {
  background-color: #E6F8EDFF;
}

.bg-secondary-light {
  background-color: #F7F7F7;
}

$grayscale-options: (
  0: $gray-25,
  1: $gray-50,
  2: $gray-100,
  3: $gray-200,
  4: $gray-300,
  5: $gray-400,
  6: $gray-500,
  7: $gray-600,
  8: $gray-700,
  9: $gray-800,
  10: $gray-900,
);

/// Set background grays using:
/// .bg-gray-0, bg-gray-7, etc
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $grayscale-option, $grayscale-option-value in $grayscale-options {
    .bg-gray-#{$grayscale-option} {
      background-color: #{$grayscale-option-value} !important;
    }
    /// Set dynamic backgrounds using:
    /// .bg-sm-gray-0, .bg-lg-gray-8, etc
    @include media-breakpoint-up($breakpoint) {
      .bg-#{$breakpoint}-gray-#{$grayscale-option} {
        background-color: #{$grayscale-option-value} !important;
      }
      .bg-#{$breakpoint}-white {
        background-color: $white !important;
      }
    }
  }
}

/// Animated loading gradient

@keyframes placeHolderShimmer{
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.bg-loading {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 100%;
}
