.border-hover {
  @each $color, $value in $theme-colors {
    &-#{$color}:hover {
      border-color: $value;
    }
  }
}

.text-hover {
  @each $color, $value in $theme-colors {
    &-#{$color}:hover {
      color: $value !important;
    }
  }
}

.bg-hover {
  @each $color, $value in $theme-colors {
    &-#{$color}:hover {
      background-color: $value !important;
    }
  }
}

.btn.btn-outline-dark-hover:hover {
  background-color: $light !important;
  color: $dark;
  border-color: $dark !important;
}
