.fixed-deck-container {
  @include media-breakpoint-up(sm) {
    max-width: 75vw;
    width: 500px;

    margin-left: auto;
    margin-right: auto;

    .fixed-deck {
      margin-bottom: 50px;
      padding-bottom: 16px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  min-height: calc(100dvh - #{$nav-height} - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
  display: flex;
  flex-direction: column;

  .fixed-deck {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 0 4px 4px rgba(0,0,0,0.25);
    background-color: #fff;

    height: 75%;
    margin-top: auto;
    display: inline-block;
  }
}

.stitch-progress {
  border-radius: 8px;
}

.next-steps {
  $icon_size: 50px;
  height: 20vh;
  min-height: calc(#{$icon_size} * 3 + 20px);
  max-height: 300px;

  .icon-path {
    @extend .vr;

    width: 2px;
    height: 100%;

    position: absolute;
    left: calc(#{$icon_size} / 2);

    opacity: 1;
    background-color: $success;
  }

  .icon-box {
    z-index: 10;
    border-radius: 10px;
    width: $icon_size;
    height: $icon_size;
    text-align: center;
  }
}
