@mixin show-gradient {
  background: linear-gradient(to top, #000, transparent 40%);
  animation: gradientSlideUp 0.25s ease-in 0s 1 normal none;
}

@mixin hide-gradient {
  animation: gradientSlideDown 0.75s ease-out 0s 1.5 normal forwards;
}

#stitches-gallery {
  .gallery-card {
    border-radius: 8px;
    overflow: hidden;
    height: 346px;
    border: none;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20),
      0px 2px 5px -2px rgba(16, 24, 40, 0.06),
      0px 8px 40px -2px rgba(16, 24, 40, 0.05);

    .gradient-layer {
      @include show-gradient;
      animation: none;
    }

    @include media-breakpoint-up(md) {
      .gradient-layer:not(:hover) {
        @include hide-gradient;
      }

      &:hover .gradient-layer {
        @include show-gradient;
      }

      &:not(:hover) .d-block-hover {
        display: none !important;
      }

      &:hover .d-block-hover {
        display: block !important;
      }
    }

    &.gallery-card-loading {
      .loading-animation {
        animation: loadingSkeleton 1s infinite alternate;
      }
    }

    .thumbnail {
      height: 256px;
      object-fit: cover;
    }

    .progress.gallery-progress {
      $height: 5px;

      height: $height;
      margin-top: -$height;
      border-radius: 0 0 4px 4px;
    }

    .card-title {
      font-weight: 500 !important;
    }
  }
}

@keyframes gradientSlideUp {
  0% {
    opacity: 0.1;
    transform: translateY(45px);
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes gradientSlideDown {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.1;
    transform: translateY(45px);
    background: none; /* Set the background to none at the end of the animation */
  }
}

@keyframes loadingSkeleton {
  from {
    opacity: .4;
  }
  to {
    opacity: 1;
  }
}
