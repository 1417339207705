.underlined-tabs {
  border-bottom: 3px solid $gray-300;
  margin-left: 0;

  .active {
    border-bottom: 3px solid $dark;
    margin-bottom: -3px;
    font-weight: bold;
  }
}
