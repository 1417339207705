.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;

  .page-item {
    text-align: center;

    &.prev, &.next {
      a {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    // Expand on small screens
    @include media-breakpoint-down(sm) {
      flex-grow: 1;
      max-width: 25%;

      &.prev, &.next {
        flex-grow: 0;
        min-width: 15%;
      }
    }
  }
}
