// Litera 5.1.3 (custom variant)
// Bootswatch

$theme: "litera" !default;

// Color system

$white:     #fff      !default;
$gray-25:   #FCFCFD   !default;
$gray-50:   #F9FAFB   !default;
$gray-100:  #F3F4F6   !default;
$gray-200:  #E5E7EB   !default;
$gray-300:  #D2D6DB   !default;
$gray-400:  #9DA4AE   !default;
$gray-500:  #6C737F   !default;
$gray-600:  #4D5761   !default;
$gray-700:  #384250   !default;
$gray-800:  #1F2A37   !default;
$gray-900:  #111927   !default;
$black:     #000      !default;

$blue:            #5184E5 !default;
$indigo:          #6610f2 !default;
$purple:          #6f42c1 !default;
$light-purple:    #7879F1 !default;
$pink:            #F178B6 !default;
$light-red:       #FEE4E2 !default;
$red:             #B42318 !default;
$orange:          #fd7e14 !default;
$yellow:          #f0ad4e !default;
$light-green:     #ddeade !default;
$green:           #317a32 !default;
$teal:            #20c997 !default;
$light-cyan:      #CFF9FE !default;
$cyan:            #0E7090 !default;

$primary:       $gray-900   !default;
$secondary:     $gray-500   !default;
$success:       $green      !default;
$info:          $blue       !default;
$warning:       $yellow     !default;
$danger:        $red        !default;
$light:         $white      !default;
$dark:          $black      !default;

$min-contrast-ratio:   1.85 !default;

// Body

$body-color:                $gray-800 !default;
$body-bg:                    $gray-25 !default;

// Breadcrumbs

$breadcrumb-divider: quote('>');

// Fonts

// stylelint-disable value-keyword-case
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-serif:           Georgia, Cambria, "Times New Roman", Times, serif !default;
// stylelint-enable

$font-size-base:              1.1rem !default;
$headings-font-weight:        700 !default;

// Tables

$table-border-color:          rgba(0, 0, 0, .1) !default;

$table-bg-scale:              0 !default;

// Buttons

$input-btn-padding-y:         .5rem !default;
$input-btn-padding-x:         1.1rem !default;
$btn-font-family:             $font-family-sans-serif !default;
$btn-font-size:               .875rem !default;
$btn-font-size-sm:            .688rem !default;

$btn-border-radius-lg:        2.688em !default;
$btn-border-radius-sm:        .844em !default;

// Forms

$input-border-color:                    rgba(0, 0, 0, .1) !default;
$input-group-addon-bg:                  $gray-200 !default !default;

// Navbar

$navbar-dark-color:                 rgba($white, .6) !default;
$navbar-dark-hover-color:           $white !default;
$navbar-light-hover-color:          $body-color !default;
$navbar-light-active-color:         $body-color !default;

// Tooltips

$tooltip-font-size:                 11px !default;

// Badges

$badge-font-weight:                 500 !default;
$badge-padding-y:                   .6em !default;
$badge-padding-x:                   1.2em !default;

// Border Radii

$border-radius-lg: 0.5rem;
$border-radius-xl: 0.75rem;
$border-radius-xxl: 1.25rem;

// Modals

$modal-content-bg:                  $gray-25;
