$dropdown-min-width:                15rem;
$dropdown-padding-x:                0;
$dropdown-padding-y:                .5rem;
$dropdown-spacer:                   .125rem;
$dropdown-font-size:                $font-size-base;
$dropdown-color:                    $white;
$dropdown-bg:                       $black;
$dropdown-border-color:             none;
$dropdown-border-radius:            $border-radius;
$dropdown-border-width:             $border-width;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg:               $dropdown-border-color;
$dropdown-divider-margin-y:         $spacer * .5;
$dropdown-box-shadow:               $box-shadow;

$dropdown-link-color:               $white;
$dropdown-link-hover-color:         $white;
$dropdown-link-hover-bg:            $black;

$dropdown-link-active-color:        $white;
$dropdown-link-active-bg:           $black;

$dropdown-link-disabled-color:      $gray-500;

$dropdown-item-padding-y:           $spacer * .25;
$dropdown-item-padding-x:           $spacer;

$dropdown-header-color:             $gray-600;
$dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x;

$caret-vertical-align:        $caret-width * .85;
$caret-spacing:               $caret-width * .85;
