#slide-deck-container {
  @extend .fixed-bottom;

  display: flex;
  flex-direction: column;
  height: 75vh;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;

  background-color: #fff;
  box-shadow: 0 0 37px 27px rgba(0,0,0,0.63);

  @include media-breakpoint-up(sm) {
    max-width: 75vw;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .deck-slide {
    text-align: center;
  }

  .slide-title {
    margin-top: 16px;
  }

  .slide-media {
    @extend .ratio, .ratio-16x9;
    margin-top: 16px;

    &.media-image {
      img {
        object-fit: cover;
        border-radius: 20px;
      }
    }

    &.media-video {
      video {
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }

  .slide-icon {
    margin-top: 16px;
  }

  .slide-body {
    margin-top: 16px;

    p {
      color: $gray-600;
    }
  }

  .deck {
    height: 100%;
    .carousel-inner { height: 100% }
    .carousel-item { height: 100%; }
  }
}
