/// Generates edge-specific classes for coloring borders
///
/// Examples:
/// .border-start-info
/// .border-top-success
/// .border-end-dark
/// .border-bottom-primary
@each $color, $value in $theme-colors {
  .border-start-#{$color} {
    border-left-color: $value !important;
  }

  .border-top-#{$color} {
    border-top-color: $value !important;
  }

  .border-end-#{$color} {
    border-right-color: $value !important;
  }

  .border-bottom-#{$color} {
    border-bottom-color: $value !important;
  }
}

/// Set border to none on different breakpoints
/// .border-sm-0, .border-md-0, etc.
///
/// Set border-start, border-top, border-end, border-bottom to different options on different breakpoints
/// Ex: .border-start-md-3, .border-start-lg-5

$border-options: (
  0: 0px solid $secondary,
  1: 1px solid $secondary,
  2: 2px solid $secondary,
  3: 3px solid $secondary,
  4: 4px solid $secondary,
  5: 5px solid $secondary
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $name, $value in $border-options {
    @include media-breakpoint-up($breakpoint) {
      .border-#{$breakpoint}-0 {
        border: 0 !important;
      }

      .border-start-#{$breakpoint}-#{$name}-secondary {
        border-left: #{$value} !important;
      }

      .border-top-#{$breakpoint}-#{$name}-secondary {
        border-top: #{$value} !important;
      }

      .border-end-#{$breakpoint}-#{$name}-secondary {
        border-right: #{$value} !important;
      }

      .border-bottom-#{$breakpoint}-#{$name}-secondary {
        border-bottom: #{$value} !important;
      }
    }
  }
}
