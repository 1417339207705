/// Set line-clamp size using .line-clamp-1 through .line-clamp-10

@for $limit from 1 through 10 {
  .line-clamp-#{$limit} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $limit;

    overflow: hidden;
  }
}
