// Checkbox shaped like a button
// Toggles checkbox icon and border color

/*
  %input#foo.check-btn{ autocomplete: 'off', type: 'checkbox', checked: true }
  %label.btn.btn-outline-secondary{ for: 'foo' }
    %span.check-icon
    .d-inline.lh-1.ps-2 Foobar
*/

.check-btn {
  display: none;

  & ~ label .check-icon {
    @extend .bi;
    @extend .bi-square;
  }

  &:checked ~ label {
    @extend .btn-outline-primary;

    .check-icon {
      @extend .bi-check-square;
    }
  }
}
