.preloader-banner.pswp__preloader--active {
  background-color: $black !important;
  color: $white !important;

  border-radius: 5px !important;
  padding-left: 2rem !important;
  width: 300px !important;

  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  position: absolute !important;
  top: 95dvh !important;
  left: 50dvw !important;
  transform: translate(-50%, -50%) !important;

  @include media-breakpoint-only(lg) {
    top: 85dvh !important;
  }

  @include media-breakpoint-only(md) {
    top: 75dvh !important;
  }

  @include media-breakpoint-down(md) {
    top: 60dvh !important;
  }

  @include media-breakpoint-down(sm) {
    top: 70dvh !important;
  }

  .preloader-banner-label {
    margin-left: 1rem;
    font-size: x-large;
  }
}
