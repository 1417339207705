// Badges

.badge-danger {
  background-color: $light-red !important;
  color: $red;
}

.badge-success {
  background-color: $light-green !important;
  color: $success;
}

.badge-info {
  background-color: $light-cyan !important;
  color: $cyan;
}

.badge-primary {
  background-color: $primary !important;
  color: $white;
  border: 1px solid $white !important;
}

.badge-light-secondary {
  &:hover {
    background-color: $gray-200 !important;
  }
  color: $primary !important;
  border: 1px solid $gray-200 !important;
}

.badge-secondary {
  background-color: $white !important;
  color: $black !important;
  border: 1px solid $gray-400 !important;
}
