/// Show/hide/style elements based on collapsible state
///
/// Examples:
/// .collapsible.collapsed
///   .d-collapsed-none Visible when not collapsed!
///   .d-none.d-collapsed-block Visible when collapsed!

// .d-collapsed-none, .d-collapsed-block
.collapsible.collapsed {
  .d-collapsed-none {
   display: none !important;
  }
  .d-collapsed-block {
   display: block !important;
  }
}

// .btn-collapsed-secondary
.collapsible.btn-collapsed-secondary {
  &.collapsed {
   background-color: $gray-200;
  }
  &:not(.collapsed) {
   background-color: $gray-50;
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
   font-weight: bold;
   border-color: $gray-300 $gray-300 $gray-50 $gray-300;
  }
}
