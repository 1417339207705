// ===== v5.1 =====

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: .25;
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

// ===== v5.2 =====

$border-radius-xl: 1rem !default;
$border-radius-xxl: 2rem !default;
