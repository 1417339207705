.tutorial-deck {
  p {
    color: $design-color-text-grey !important; // scss-lint:disable ImportantRule
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }

  .title {
    color: $design-color-text-darkest-navy;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .subtitle {
    color: $design-color-text-grey;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 21px;
    text-transform: uppercase;
  }

  video {
    border-radius: $border-radius-xxl;
    height: 100%;
    width: 100%;
  }

  // consider moving to slide deck CSS or adding an ID to btn based on deck name
  .btn {
    width: 100%;
  }

  .deck-slide {
    text-align: initial !important; // scss-lint:disable ImportantRule - bypass specificity of template
  }

  // scss-lint:disable IdSelector
  #slide-deck-container {
    // Make deck a bit taller.
    height: 85vh;

    // == Make deck scrollable related to "scrollable" deck
    // NOTE: this looks bad w/ multi-slide due to indicator
    .deck {
      height: 95%;
    }

    .carousel-item.slide.active {
      max-height: 98%;
      overflow: scroll;
    }

    .carousel-item.slide.active::-webkit-scrollbar {
      display: none;
    }
  }
  // scss-lint:enable IdSelector
}
