//
// Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
// the trix-editor content (whether displayed or under editing). Feel free to incorporate this
// inclusion directly in any other asset bundle and remove this file.
//

@import 'trix/dist/trix';

trix-toolbar {
  margin: 10px;

  .trix-button-group {
    margin: 0;
    border: none;

    .trix-button {
      border: none !important;

      &--icon {
        width: 1.7em;
      }

      &--custom-icon {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }
}

trix-editor {
  &.form-control {
    min-height: 20rem;
    height: auto;
    border-left: 0;
    border-right: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

trix-editor, .trix-content {
  centered-div {
    display: block;
    text-align: center;
  }

  .attachment--preview {
    width: auto;

    .attachment__caption {
      text-align: center !important;
    }
  }
}

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    display: block;
    text-align: center;

    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }

  .figure-caption {
    display: flex;

    // A div we're going to add to the _blob.html.erb partial. This little trick
    // ensures the caption will never grow wider than the image above it.
    div {
      flex-grow: 1;
      width: 0;
    }
  }

  // Editor only. Hide the extra caption in the editor.
  .attachment__caption {
    display: none;
  }
}
