// Auto-expand text area fields to 150px in height while focused.

.shy-text-area {
  transition: all 0.5s ease-in-out;

  &:focus-within {
    min-height: 150px;
    transition: all 0.5s ease-in-out;
  }
}
