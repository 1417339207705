/// Use in conjunction with vertical_nav_controller
// Example Usage:
/*
= stimulate_with :vertical_nav do |nav|
  .vertical-nav.gap-1{ data: nav.controller.action('turbo:load@document': :updateLinks) }
    %a.nav-link{ href: '#', data: nav.target(:primaryLink) } My Profile

    = link_to 'Manage', '#', class: 'nav-link', data: nav.target(:primaryLink).merge(bs_target: '#dropdown-1')
    #dropdown-1.collapse
      .dropdown-nav-list.fs-7
        = link_to 'General', '#', class: 'link-dark', data: nav.target(:nestedLink)
        = link_to 'Billing', request.path, class: 'link-dark', data: nav.target(:nestedLink)
        = link_to 'Integrations', '#', class: 'link-dark', data: nav.target(:nestedLink)
        = link_to 'Team', '#', class: 'link-dark', data: nav.target(:nestedLink)

    %a.nav-link{ href: '#', data: nav.target(:primaryLink) } Admin Panel
*/

.vertical-nav {
  display: flex;
  flex-direction: column;

  .nav-link {
    user-select: none;
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: 600;
    color: $secondary;

    &.active, &:hover {
      color: $primary;
      background-color: $gray-100;
    }

    &.active {
      border-left: $primary solid 2px;
    }
  }

  .dropdown-nav-list {
    display: flex;
    flex-direction: column;

    a {
      padding-top: 0.4rem;
      padding-left: 1.25rem;
      text-decoration: none;

      &.active, &:hover, &:focus {
        font-weight: bold;
      }
    }
  }
}
