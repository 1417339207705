.active {
  .d-active-block {
    display: block !important;
  }
  .d-active-inline-block {
    display: inline-block !important;
  }
  .d-active-flex {
    display: flex !important;
  }
  .d-active-inline-flex {
    display: inline-flex !important;
  }
}
