.banner {
  height: 23px;
  margin: 5px;
  padding: 0;
  position: absolute;
  font-size: 18px;

  color: $white;
  background: $primary;

  &:before { right:100%; }
  &:after { left:100%; }

  &.banner-tr-primary {
    top: -3px;
    right: -5px;
    transform-origin: top left;
    transform: translateX(30%) translateY(0%) rotate(45deg);

    &:before, &:after {
      content: '';
      width: 200%;
      height: 100%;
      top: 0;
      margin: 0 -1px;
      position: absolute;
      background: $primary;
    }
  }
}
