.unlocked-stitch-card {
  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      min-width: 100%;
      min-height: 100%;
    }
  }
}
