/// Bootstrap v5 does not currently have an Object
/// Fit utility so we stole from Bootstrap v5.3
///
/// Link to Docs for v5.3 Object Fit classes https://getbootstrap.com/docs/5.3/utilities/object-fit/

$fit-options: (
  contain: contain,
  cover: cover,
  fill: fill,
  scale: scale-down,
  none: none
);

/// Sets Object Fit with
/// Ex: object-fit-contain, object-fit-cover, etc
@each $name, $value in $fit-options {
  .object-fit-#{$name} {
    object-fit: $value !important;
  }
}

/// Sets responsive Object Fit with
/// Ex: object-fit-md-contain, object-fit-lg-cover, etc
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $name, $value in $fit-options {
    @include media-breakpoint-up($breakpoint) {
      .object-fit-#{$breakpoint}-#{$name} {
        object-fit: $value !important;
      }
    }
  }
}
