.p-clickable {
  cursor: pointer;
}

.p-default {
  cursor: default;
}

.p-help {
  cursor: help;
}

.p-zoomable {
  cursor: zoom-in;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .p-#{$breakpoint}-clickable {
      cursor: pointer;
    }

    .p-#{$breakpoint}-default {
      cursor: default !important;
    }

    .p-#{$breakpoint}-help {
      cursor: help !important;
    }

    .p-#{$breakpoint}-zoomable {
      cursor: zoom-in;
    }
  }
}
