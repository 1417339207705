$offcanvas-padding-y:               $modal-inner-padding;
$offcanvas-padding-x:               $modal-inner-padding;
$offcanvas-horizontal-width:        350px;
$offcanvas-vertical-height:         100vh;
$offcanvas-transition-duration:     .3s;
$offcanvas-border-color:            #141416;
$offcanvas-border-width:            1px;
$offcanvas-title-line-height:       $modal-title-line-height;
$offcanvas-bg-color:                $black;
$offcanvas-color:                   $white;
$offcanvas-box-shadow:              $modal-content-box-shadow-xs;
