$nav-tabs-border-color:             transparent transparent $gray-50 transparent;
$nav-tabs-border-width:             $border-width;
$nav-tabs-border-radius:            0;
$nav-tabs-link-hover-border-color:  $nav-tabs-border-color;
$nav-tabs-link-active-color:        $gray-700;
$nav-tabs-link-active-bg:           $body-bg;
$nav-tabs-link-active-border-color: transparent transparent $black transparent;

.nav-item {
  user-select: none;
}
