// https://github.com/twbs/bootstrap/tree/4c7a3e8adf73bf55a5fba5905b356468be17bc0e

// Litera 5.0.0-beta2
// Bootswatch

// Navbar

.navbar {
  font-size: $font-size-sm;
  color: $white !important;

  &.bg-dark {
    background-color: $dark !important;
  }

  &.bg-light {
    background-color: $white !important;
    border: 1px solid rgba(0, 0, 0, .1);

    &.navbar-fixed-top {
      border-width: 0 0 1px;
    }

    &.navbar-fixed-bottom {
      border-width: 1px 0 0;
    }
  }
}

// Typography

p {
  font-family: $font-family-serif;
}

blockquote {
  font-style: italic;
}

footer {
  font-size: $font-size-sm;
}

.lead {
  color: $gray-600;
  font-family: $font-family-sans-serif;
}

// Tables

table,
.table {
  font-size: $font-size-sm;
}

// Navs

.nav,
.breadcrumb,
.pagination {
  font-size: $font-size-sm;
}

.dropdown-menu {
  font-size: $font-size-sm;
}

// Indicators

.badge {
  vertical-align: bottom;
}

// Containers

.list-group {
  font-size: $font-size-sm;
}

// Button Colors

.btn-dark {
  background-color: $gray-900 !important;
  color: $white !important;
}

.btn.disabled{
  opacity: 0.3;
}

.btn-outline-dark {
  border: 1px solid $black;
  background-color: $white;
  color: $black;
  &:hover {
    color: $white !important;
  }
  &.btn-check:checked {
    color: $white !important;
  }
}

.btn-outline-secondary {
  border: 1px solid $gray-600;
  background-color: $white;
  color: $gray-600;
}
