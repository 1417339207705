.duplicatable-field-container {
  .duplicatable-field-entry {
    // Hide delete button in the last group
    &:last-child .remove-button {
      display: none !important;
    }

    // Hide duplicate button except in the last group
    &:not(:last-child) .duplicate-button {
      display: none !important;
    }
  }
}
