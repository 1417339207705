/// For use with the spinner-overlay controller

#spinner-overlay {
  @extend .position-absolute;

  $padding: 10px;
  left: $padding;
  top: $padding;
  height: calc(100% - 2 * #{$padding});
  width: calc(100% - 2 * #{$padding});

  #spinner-container {
    @extend .position-relative;
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .h-100;
    @extend .bg-white;

    #spinner {
      @extend .spinner-border;
      @extend .my-auto;
      @extend .p-4;
    }
  }
}
