.description {
  color: $gray-700;
  line-height: 1;
  margin-bottom: 8px;
}

.text-gray {
  color: $gray-700;
}

.text-gray-200 {
  color: $gray-200;
}

.text-cyan {
  color: $cyan;
}

.text-pink {
  color: $pink;
}

.text-dark-pink {
  color: $dark-pink;
}

.lh-1-4 {
  line-height: 1.4;
}

.fs-7 {
  font-size: 0.9em !important;
}

.fs-8 {
  font-size: 0.8em !important;
}

.fs-9 {
  font-size: .7em !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .fs-#{$breakpoint}-1 {
      @include rfs($h1-font-size !important);
    }
    .fs-#{$breakpoint}-2 {
      @include rfs($h2-font-size !important);
    }
    .fs-#{$breakpoint}-3 {
      @include rfs($h3-font-size !important);
    }
    .fs-#{$breakpoint}-4 {
      @include rfs($h4-font-size !important);
    }
    .fs-#{$breakpoint}-5 {
      @include rfs($h5-font-size !important);
    }
    .fs-#{$breakpoint}-6 {
      @include rfs($h6-font-size !important);
    }
    .fs-#{$breakpoint}-7 {
      font-size: .9em !important;
    }
    .fs-#{$breakpoint}-8 {
      font-size: .8em !important;
    }
    .fs-#{$breakpoint}-9 {
      font-size: .7em !important;
    }
  }
}
