// Styles flash messages as dismissible alerts

#flashes-container {
  @extend .container, .fixed-bottom;
  align-items: center;

  .flash {
    @extend .alert, .alert-dismissible, .px-5;
    @extend .border;

    background-color: #fff;
    display: flex;
    z-index: 100;

    &:not(:hover) { opacity: .95; }
    &.alert { @extend .border-danger, .text-danger; }
    &.notice { @extend .border-success, .text-success; }

    .flash-text-icon {
      @extend .h4, .bi, .me-3, .mb-0;
    }

    .flash-text {
      margin: 0;
    }

    .flash-dismiss-icon {
      @extend .h4, .bi, .p-0, .mb-0, .ms-auto;
      cursor: pointer;
      &:not(:hover) { opacity: .5; }
    }
  }
}
